var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cpt-home-cesium_layer"},[_c('div',{staticClass:"bk1027",class:{
    //isFullscreenBool 为true时代表全屏
    ycFlag_01: _vm.ycFlag && _vm.ycnum == 0 && !_vm.isFullscreenBool,
    ycFlag_01_full: _vm.ycFlag && _vm.ycnum == 0 && _vm.isFullscreenBool,
    ycFlag_02: _vm.ycFlag && _vm.ycnum == 1,
    ycFlag_02_full: _vm.ycFlag && _vm.ycnum == 1 && _vm.isFullscreenBool,
    ycFlag_03: _vm.ycFlag && _vm.ycnum == 2,
    ycFlag_03_full: _vm.ycFlag && _vm.ycnum == 2 && _vm.isFullscreenBool,
    'ycFlag--collapse': _vm.ycMapCollapse,
    wrjFlag: _vm.wrjFlag,
    wrjFlag1: _vm.wrjFlag1,
    fkVideo: _vm.fkVideo,
    yc1027: _vm.yPath == true,
    uav1027: _vm.uavpath == true,
  },attrs:{"id":"layer-container"}}),_vm._l((_vm.videoPopups),function(videoPopup,index){return _c('DTVideo',{key:index,attrs:{"title":videoPopup.title,"videoUrl":videoPopup.videoUrl,"fullScreen":videoPopup.fullScreen},on:{"closeVideo":function($event){return _vm.closeVideo(index)},"fullScreen":function($event){return _vm.toggleFullScreen(index)}}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }