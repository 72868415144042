<template>
  <!-- 清流融合 -->
  <div class="video-container" :class="{ 'full-video': isStatus }" ref="wrap">
    <div class="interact-wrap">
      <div class="cpt_video" :class="big" @click="fn" v-if="flag" ref="video" v-interact>
        <Obstacle v-if="!isStatus"></Obstacle>
        <div class="video">
          <div class="name" v-clipboard:copy="vUrl.vUrl">{{ data.orgName }}-{{ data.deviceName }}</div>
          <div class="type" v-if="networkType">{{ networkType }}</div>
          <!-- 隐藏原画字样 -->
          <div class="types" v-show="false">{{ streamName }}</div>
          <div v-show="!isStatus" class="close" @click="close(data)">关闭</div>
        </div>
        <el-tooltip v-show="false" content="模式切换" placement="bottom">
          <div class="cp pa iconfont icon-moshiqiehuan cf modelStyle" @click="change"></div>
        </el-tooltip>
        <components :is="playerCom" :videoFlag="videoFlag" :isStatus="isStatus" ref="player" :fpvUrl="fpvUrl" :data="vUrl"
          :infoflag="infoflag" :raw_msg="raw_msg" @close="infoflag = false" />
        <div class="info" v-if="infoshow && streamSelect == 'QingLiu' && !isStatus">
          <div class="de">
            <div class="title">bitrate:</div>
            <div class="detail">{{ raw_msg ? raw_msg / 1000 : "0" }}kbps</div>
          </div>
          <div class="de">
            <div class="title">venc_avg:</div>
            <div class="detail">{{ infoData ? infoData.venc_avg / 1000 : "0" }}ms</div>
          </div>
          <div class="de">
            <div class="title">venc_last:</div>
            <div class="detail">{{ infoData ? infoData.venc_last / 1000 : "0" }}ms</div>
          </div>
          <!-- <div class="de">
            <div class="title">push_path:</div>
            <div class="detail"></div>
          </div>
          <div class="de">
            <div class="title">recv_path:</div>
            <div class="detail"></div>
          </div>-->
          <div class="de">
            <div class="title">pushrtt_avg:</div>
            <div class="detail">{{ infoData ? infoData.pushrtt_avg[0] / 1000 : "0" }}ms</div>
          </div>
          <div class="de">
            <div class="title">pushrtt_last:</div>
            <div class="detail">{{ infoData ? infoData.pushrtt_last[0] / 1000 : "0" }}ms</div>
          </div>
          <div class="de">
            <div class="title">recvrtt_avg:</div>
            <div class="detail">{{ infoData ? infoData.recvrtt_avg[0] / 1000 : "0" }}ms</div>
          </div>
          <div class="de">
            <div class="title">recvrtt_last:</div>
            <div class="detail">{{ infoData ? infoData.recvrtt_last[0] / 1000 : "0" }}ms</div>
          </div>
          <div class="de">
            <div class="title">pushretrans_avg:</div>
            <div class="detail">{{ infoData ? (infoData.pushretrans_avg[0] * 0.1).toFixed(2) : "0" }}%</div>
          </div>
          <div class="de">
            <div class="title">pushretrans_last:</div>
            <div class="detail">{{ infoData ? (infoData.pushretrans_last[0] * 0.1).toFixed(2) : "0" }}%</div>
          </div>
          <div class="de">
            <div class="title">recvretrans_avg:</div>
            <div class="detail">{{ infoData ? (infoData.recvretrans_avg[0] * 0.1).toFixed(2) : "0" }}%</div>
          </div>
          <div class="de">
            <div class="title">recvretrans_last:</div>
            <div class="detail">{{ infoData ? (infoData.recvretrans_last[0] * 0.1).toFixed(2) : "0" }}%</div>
          </div>
          <!-- <div class="de">
            <div class="title">pzvtdelay:</div>
            <div class="detail">{{infoData ? infoData.pzvtdelay : "0"}}</div>
          </div>-->
          <div class="de">
            <div class="title">vdec:</div>
            <div class="detail">{{ infoData ? infoData.vdec / 1000 : "0" }}ms</div>
          </div>
          <div class="de">
            <div class="title">vbuffer:</div>
            <div class="detail">{{ infoData ? infoData.vbuffer / 1000 : "0" }}ms</div>
          </div>
          <!-- <div class="de">
            <div class="title">abuffer:</div>
            <div class="detail"></div>
          </div>-->
        </div>
        <!-- 瞄准 -->
        <!-- <div class="kedu" :style="backgroundStyle" v-if="showCenter"> -->
        <div class="kedu" :style="backgroundStyle" v-show="false">
          <div class="num">
            <div class="first">{{ num.one }}</div>
            <div class="first">{{ num.two }}</div>
            <div class="first">{{ num.three }}</div>
            <div class="first">{{ num.four }}</div>
            <div class="first">{{ num.five }}</div>
            <div class="first">{{ num.six }}</div>
            <div class="first">{{ num.seven }}</div>
          </div>
          <div class="center">
            <img src="@/assets/images/mount/center.png" alt />
          </div>
          <div class="longLine">
            <img src="@/assets/images/mount/line2.png" alt />
          </div>
          <!-- <img :style="{transform: `translateY(${uavData && uavData.gps ? uavData.gps.relativeAlt : 120}%)`}" class="line" src="@/assets/images/mount/line.png" alt=""> -->
          <div class="text">
            <span class>{{ uavData && uavData.gps ? uavData.gps.relativeAlt : 0 }}</span>
            <span class style="color:black">m</span>
          </div>
        </div>

        <div v-show="false" class="play">
          <el-tooltip v-if="streamSelect == 'QingLiu'" class="left ml24 mt5 w20" content="信息" placement="bottom">
            <div class="cp" @click="getInfo" style="transform: scale(1.4);">
              <img src="@/assets/info.png" alt />
            </div>
          </el-tooltip>

          <div v-else class="left ml24"></div>
          <el-tooltip content="是否启动瞄准镜" placement="bottom">
            <div class="cf ml60 pa  top11 cp " style="zIndex:10" @click="showCenterFn">
              <img src="@/assets/images/mount/center.png" alt />
            </div>
          </el-tooltip>
          <div class="right mr14">
            <div class="search mr22">
              <el-select class="video_type mr24" v-model="streamSelect" placeholder="切换源">
                <el-option v-for="item in streamOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
              <el-select class="big_type" v-model="sizeSelect" placeholder="比例" @change="changeBig">
                <el-option v-for="item in sizeOptions2" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="pointer iconfont icon-gengxin cf f16 mr26" @click="reset"></div>
            <div class="pointer iconfont icon-quanping cf f16" @click="screen"></div>
          </div>
        </div>

        <el-tooltip v-show="false" content="拍照" placement="bottom">
          <div class="cp pa right10 cf plate" @click="photojz">
            <img src="@/assets/newImage/ai.png" />
          </div>
        </el-tooltip>
        <el-tooltip v-show="true" class="cp pa right10 cf ai" content="AI识别" placement="bottom">
          <!-- <div @click="aiVisible = !aiVisible">
            <img src="@/assets/newImage/car.png" />
          </div> -->
          <!-- 东台项目 -->
          <el-button type="text" @click="handleAI"> {{ aiMode ? '关闭AI' : '打开AI' }}</el-button>
        </el-tooltip>
        <!-- <el-tooltip v-if="!record" content="开始录像" placement="bottom">
          <div class="cp pa right10 cf record" @click="getrecord">
            <img src="@/assets/newImage/record.png" />
          </div>
        </el-tooltip>
        <el-tooltip v-else content="停止录像" placement="bottom">
          <div class="cp pa right10 cf record" @click="getrecord">
            <img src="@/assets/newImage/stop.png" />
          </div>
        </el-tooltip> -->
        <el-tooltip v-show="false" content="截图" placement="bottom">
          <div class="cp pa right10 cf photojz" @click="photo">
            <img src="@/assets/newImage/photojz.svg" />
          </div>
        </el-tooltip>
        <div class="faceAndcar cp pa right70 cf" v-if="aiVisible">
          <el-tooltip content="人脸识别" placement="bottom">
            <div class="plan" @click="handle('a')">
              <img src="~@/assets/images/observe/faceAI2.png" />
            </div>
          </el-tooltip>
          <el-tooltip content="车辆识别" placement="bottom">
            <div class="plan" @click="handle('b')">
              <img src="~@/assets/images/observe/carAI2.png" />
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <FaceAI v-if="faceAiShow" :uavId="device.deviceHardId" @closeface="faceAiShow = false" />
    <CarAI v-if="carAiShow" :uavId="device.deviceHardId" :list="carList" @closecar="carAiShow = false" />

    <el-dialog title="识别类型" :visible.sync="dialogFormVisible" append-to-body center width="20%" custom-class="custom-dialog">
      <el-form :model="aiForm">
        <el-form-item label="刷新频率" :label-width="`80px`">
          <el-input-number v-model="aiForm.rate" :min="1" :max="1000" controls-position="right"></el-input-number>
        </el-form-item>
        <el-form-item label="识别类型" multiple :label-width="`80px`">
          <el-select v-model="aiForm.detectionTypes" multiple placeholder="识别类型" value-key="dictValue">
            <el-option v-for="item in aiOptions" :key="item.dictValue" :label="item.dictName" :value="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureAIOpen">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api";

import FaceAI from "@/components/observe/uav/faceai";
import CarAI from "@/components/observe/uav/carai";
import LiveNVRPlayer from "./livenvr/index.vue";
// import QingLiuPlayer from "./flv/index.vue";
import QingLiuPlayer from "./qingliu/index.vue";
import SRSPlayer from './srs';
import { to_moveMount } from "@/utils/to_moveMount.js";
import Bus from "@/assets/ligature.js";
import Obstacle from "./obstacle";

export default {
  name: "Float-Playerfk",
  components: {
    QingLiuPlayer,
    LiveNVRPlayer,
    FaceAI,
    CarAI,
    Obstacle,
    SRSPlayer
  },
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
    // showCenter: {
    //   type: Boolean,
    //   default : true
    // }
  },
  data () {
    return {
      backgroundStyle: {
        "background-position-y": 0,
      },
      showCenter: false,
      fuseFlag: true, //清流是否进行视频融合true为融合
      controlflag: false, //清流 是否需要画中画主次画面切换，true为切换
      record: false,
      infoshow: false, //清流视频信息展示
      infoData: null, //清流信息
      raw_msg: 0,
      imgshow: false, //弹出的图片显示
      imgshowurl: "", //弹出的图片路径
      carList: [], //车牌识别结果
      aiVisible: false, //打开ai
      faceAiShow: false,
      carAiShow: false,
      flag: true,
      isPlay: true,
      videoFlag: true,
      isStatus: false,
      big: "",
      streamSelect: "QingLiu", //选择的流
      sizeSelect: 2, //选择的大小
      sizeOptions2: [
        //大小可选项
        {
          value: 1,
          label: "X1",
        },
        {
          value: 2,
          label: "X2",
        },
        {
          value: 3,
          label: "X3",
        },
      ],
      infoflag: false,
      mountData: {},
      num: {
        one: 15,
        two: 10,
        three: 5,
        four: 0,
        five: -5,
        six: -10,
        seven: -15,
      },
      aiMode: false,
      aiEnable: false,
      dialogFormVisible: false,
      aiForm: {
        rate: 10,
        detectionTypes: [],
      },
      aiOptions: [
        {
          "dictName": "车辆检测",
          "dictValue": "car",
          "dictConf": 0.5,
        },
        {
          "dictName": "人物检测",
          "dictValue": "person",
        },
        {
          "dictName": "城市道路车辆超速预警",
          "dictValue": "city_vehicle_speed_estimation",
        },
        {
          "dictName": "人脸检测",
          "dictValue": "human_face",
        },
        {
          "dictName": "车牌检测",
          "dictValue": "plateDetect",
        },
        {
          "dictName": "道路病害",
          "dictValue": "road_diseases",
        },
        {
          "dictName": "挖掘机检测",
          "dictValue": "excavator",
        },
        {
          "dictName": "头盔检测",
          "dictValue": "safety_helmet",
        },
        {
          "dictName": "密集人群人数统计",
          "dictValue": "dense_crowds",
        },
        {
          "dictName": "地面垃圾检测",
          "dictValue": "ground_waste",
        },
        {
          "dictName": "船只检测",
          "dictValue": "boat",
        },
        {
          "dictName": "漂浮物",
          "dictValue": "floatage",
        },
        {
          "dictName": "夜间红外人员检测",
          "dictValue": "infrared_person",
        },
        {
          "dictName": "夜间红外车辆检测",
          "dictValue": "infrared_car",
        },
        {
          "dictName": "火情检测",
          "dictValue": "fire",
        },
        {
          "dictName": "光伏电板缺陷检测",
          "dictValue": "photovoltaic_panel_defect",
        },
        {
          "dictName": "蓝藻",
          "dictValue": "blue_green_algae",
        },
        {
          "dictName": "水葫芦",
          "dictValue": "water_hyacinth",
        },
        {
          "dictName": "排水口",
          "dictValue": "pipe",
        },
      ]
    };
  },
  computed: {
    /**
     * 播放器组件名
     */
    playerCom () {
      /* if (this.streamSelect === "QingLiu") {
        return "QingLiuPlayer";
      }
      return "LiveNVRPlayer"; */
      // return 'SRSPlayer';
      // 2023年10月9日 14:15:57
      // if (this.vUrl?.vUrl?.includes('pzsp://')) {
      //   return "QingLiuPlayer";
      // }
      // return "LiveNVRPlayer";

      // 2023年10月9日 14:16:23
      // console.log('this.vUrl', this.vUrl);
      
      let playerName = '';
      if (this.vUrl?.vUrl?.includes('pzsp://')) {
        playerName = "QingLiuPlayer";
      } else if (this.vUrl?.vUrl?.includes('webrtc')) {
        // 播放webrtc
        playerName = "SRSPlayer";
      } else {
        playerName = "LiveNVRPlayer";
      }
      // console.log(playerName, 'playerName视频墙');

      // console.log(playerName, '==========playerName视频墙');
      // console.log(this.vUrl, '==============this.vUrl');
      // console.log(this.fpvUrl, '================this.fpvUrl');


      return playerName;
    },
    /**
     * 流类型名
     */
    streamName () {
      let find = this.streamOptions.find((item) => {
        return item.value === this.streamSelect;
      });

      return find?.label || "";
    },

    taskId () {
      return this.$store.state.fckernel.taskId;
    },
    /**
     * 网络类型
     * @returns 专网 | 公网
     */
    networkType () {
      // console.log('网络判断', this.data.videos)
      let name = "";
      try {
        if (this.data.videos.checkOnLine == 1) {
          name = "公网";
        } else {
          if (this.uavData?.link) {
            if (this.uavData?.link[0]?.priority == 2) {
              if (this.uavData?.link[0]?.using) {
                name = "专网";
              } else {
                name = "公网";
              }
            }
            if (this.uavData?.link[0]?.priority == 3) {
              if (this.uavData?.link[0]?.using) {
                name = "公网";
              } else {
                name = "专网";
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }

      return name;
    },
    /**
     * 流集合
     */
    streams () {
      let networkKey1 = "";
      let networkKey2 = "";
      if (this.networkType === "专网") {
        networkKey1 = "private";
        networkKey2 = "public";
      } else {
        networkKey1 = "public";
        networkKey2 = "private";
      }

      let streams = this.data.videos.list[networkKey1];
      if (this.networkType == "" && !streams) {
        streams = this.data.videos.list[networkKey2];
      }
      // console.log('networkKey1', networkKey1, networkKey2, this.data.videos.list[networkKey1], this.data.videos.list[networkKey2])
      return streams;
    },
    /**
     * 流选项
     * @returns [{
          value: "RTC",
          label: "低延迟"
        }]
     */
    streamOptions () {
      let streamOptions = [];
      //清流
      if (this.streams?.qingLiuUrl) {
        window.$Bus.$on("qoeinfo", (e) => {
          this.infoData = e.data;
        });
        window.$Bus.$on("raw_msg", (e) => {
          this.raw_msg = e.data;
          // this.qoeInform(this.raw_msg);
        });
        streamOptions.push({
          value: "QingLiu",
          label: "原画",
        });
      }

      //WS-FLV
      if (this.streams?.socketUrl) {
        streamOptions.push({
          value: "hlsUrl",
          label: "流畅",
        });
      }

      //WebRtc
      if (this.streams?.webUrl) {
        streamOptions.push({
          value: "WebRtc",
          label: "低延迟",
        });
      }

      return streamOptions;
    },
    /**
     * 播放的视频流
     */
    vUrl () {
      let vUrl = "";
      switch (this.streamSelect) {
        case "QingLiu":
          vUrl = this.streams?.qingLiuUrl;
          break;

        case "hlsUrl":
          if (this.device.goodsName == "M300") {
            vUrl = this.streams?.socketUrl;
          } else {
            vUrl = "http://32.128.6.46:10800" + this.streams?.hlsUrl;
          }
          break;

        case "WebRtc":
          vUrl = this.streams?.webUrl;
          break;
      }

      if(this.aiEnable) {
        if(vUrl.includes('flv')) {
          vUrl = vUrl.replace(".flv", "_SHIBIE.flv")
        } else {
          vUrl = vUrl + "_SHIBIE";
        }
      }
      // console.log(vUrl);
      
      return {
        vUrl,
      };
    },
    fpvUrl () {
      return {
        vUrl: this.streams?.fpvUrl || "",
      };
    },
  },
  watch: {
    infoData: function () {
      // this.qoeInform(this.raw_msg);
    },
    raw_msg: function (newval1) {
      // this.qoeInform(this.raw_msg);
    },
    uavData: function (newval) {
      // if(newval.gps.relativeAlt > 100){
      //   this.backgroundStyle = {
      //     "background-position-y": 30 +"px"
      //   }
      // }else{
      if (!newval?.gps) {
        return;
      }
      this.backgroundStyle = {
        "background-position-y": newval.gps.relativeAlt + "px",
      };
      this.num.one = Number(newval.gps.relativeAlt.toFixed(0)) + 15;
      this.num.two = Number(newval.gps.relativeAlt.toFixed(0)) + 10;
      this.num.three = Number(newval.gps.relativeAlt.toFixed(0)) + 5;
      this.num.four = Number(newval.gps.relativeAlt.toFixed(0)) + 0;
      this.num.five = Number(newval.gps.relativeAlt.toFixed(0)) - 5;
      this.num.six = Number(newval.gps.relativeAlt.toFixed(0)) - 10;
      this.num.seven = Number(newval.gps.relativeAlt.toFixed(0)) - 15;
      // }
    },
    "$store.state.mapmanage.mapisStatus": function (newVal) {
      if (newVal.type == "wrj") {
        if (this.videoItem == this.index) {
          this.isStatus = newVal.flag;
          if (this.isStatus) {
            console.log(this.isStatus);
          }
        }
      }
    },
    "$store.state.device.move_data": function (newVal) {
      this.mountData = newVal;
    },
    streamOptions: {
      handler (value) {
        //当流选项变化时，如果未选择流类型，则自动选择第一个流类型
        if (this.streamSelect == "" && this.streamOptions.length > 0) {
          this.streamSelect = this.streamOptions[0].value;
        }
      },
      deep: true,
      immediate: true,
    },
    streamSelect: function (val) {
      this.$store.commit("fckernel/SET_STREAM", val);
    },
    networkType () {
      let find = this.streamOptions.find((item) => {
        return item.value == this.streamSelect;
      });
      if (!find) {
        this.streamSelect = ""; //网络类型改变时，未有当前选择的流类型， 则重置
      }
    },
    deep: true,
  },
  async mounted () {
    window.$uavPlayer = this;
    this.wrapCenter();
    //视屏截图
    Bus.$on("uav_take_photo", ({ callback }) => {
      let blob = this.screenShot();
      callback && callback(blob);
    });

    let res = await API.FCKERNEL.checkUseOperate({
      deviceHardId: this.device.deviceHardId,
    });
    if (res.code == 201) {
    } else {
      // 初始化
      this.changeVideo(true, false);
    }
    this.$store.commit("fckernel/SET_STREAM", this.streamSelect);

    // 点击挂载之后，视频窗口往下移动
    Bus.$on('video-location-low', (v) => {
      if (v) {
        this.wrapMoveLow();
      } else {
        this.wrapCenter();
      }
    });
  },
  methods: {
    qoeInform (raw_msg) {
      let { infoData } = this;
      let {
        deviceHardId,
        udpStatus,
        cateId,
        cateName,
        deviceName,
        flightState,
        goodsName,
        reportStatus,
        socketStatus,
        state,
        stationType,
        store,
        firmId,
        firmName,
      } = this.device;
      let data = {
        uavId: deviceHardId,
        udpStatus,
        cateId,
        cateName,
        deviceName,
        flightState,
        goodsName,
        reportStatus,
        socketStatus,
        state,
        stationType,
        store,
        firmId,
        firmName,
        info: {
          bitrate: raw_msg ? raw_msg / 1000 + "kbps" : "0kbps",
          venc_avg: infoData ? infoData.venc_avg / 1000 + "ms" : "0ms",
          venc_last: infoData ? infoData.venc_last / 1000 + "ms" : "0ms",
          pushrtt_avg: infoData ? infoData.pushrtt_avg[0] / 1000 + "ms" : "0ms",
          pushrtt_last: infoData
            ? infoData.pushrtt_last[0] / 1000 + "ms"
            : "0ms",
          recvrtt_avg: infoData ? infoData.recvrtt_avg[0] / 1000 + "ms" : "0ms",
          recvrtt_last: infoData
            ? infoData.recvrtt_last[0] / 1000 + "ms"
            : "0ms",
          pushretrans_avg: infoData
            ? (infoData.pushretrans_avg[0] * 0.1).toFixed(2) + "%"
            : "0%",
          pushretrans_last: infoData
            ? (infoData.pushretrans_last[0] * 0.1).toFixed(2) + "%"
            : "0%",
          recvretrans_avg: infoData
            ? (infoData.recvretrans_avg[0] * 0.1).toFixed(2) + "%"
            : "0%",
          recvretrans_last: infoData
            ? (infoData.recvretrans_last[0] * 0.1).toFixed(2) + "%"
            : "0%",
          vdec: infoData ? infoData.vdec / 1000 + "ms" : "0ms",
          vbuffer: infoData ? infoData.vbuffer / 1000 + "ms" : "0ms",
        },
      };
      API.QINGLIU.qoeInform(data);
    },
    showCenterFn () {
      this.showCenter = !this.showCenter;
    },
    changeVideo (fuseFlag, controlflag) {
      let streamData = {
        data: {
          data: {
            fuseFlag,
            controlflag,
          },
          messageID: 1018,
        },
        type: 528,
      };
      this.$emit("fn", streamData);
    },
    getInfo () {
      this.infoshow = !this.infoshow;
    },
    async change () {
      if (this.streamSelect !== "QingLiu") {
        this.videoFlag = !this.videoFlag;
      } else {
        let res = await API.FCKERNEL.checkUseOperate({
          deviceHardId: this.device.deviceHardId,
        });
        if (res.code == 201) {
          this.$message.warning(res.msg);
          return false;
        } else {
          this.controlflag = !this.controlflag;
          this.changeVideo(true, this.controlflag);
        }
      }
    },
    close (data) {
      // if (this.data.videos.list[0].source == "QINGLIU") {
      //   this.$refs.SRSRef.del_player(0)
      // }
      this.$emit("close", data);
    },
    screen () {
      if (this.playerCom === "LiveNVRPlayer") {
        this.$refs.player.$refs["livePlayer"].toggleFullscreen();
      } else {
        this.infoflag = true;
        this.$refs.player.fullScreen();
      }
    },
    play () {
      if (this.playerCom === "LiveNVRPlayer") {
        this.$refs.player.$refs["livePlayer"].pause();
        this.isPlay = false;
      }
    },
    pause () {
      if (this.playerCom === "LiveNVRPlayer") {
        this.$refs.player.$refs["livePlayer"].play();
        this.isPlay = true;
      }
    },
    async reset () {
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
      });
      if (this.taskId) {
        let dataRun = {
          taskId: this.taskId,
          typeId: 3,
          deviceId: this.device.id,
        };
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
      }
    },
    changeBig (id) {
      this.big = "big_0" + id;
      this.wrapCenter();
    },
    /**
     * 截图
     */
    screenShot () {
      return this.$refs.player.screenShot();
    },
    async getrecord () {
      // return this.$message.info("功能开发中...");
      this.record = !this.record;
      if (this.record) {
        this.$message.success("开始录像！");
      } else {
        this.$message.success("停止录像！");
      }
      let streamData = {
        data: {
          data: {
            taskID: this.taskId,
            videoID: 1,
          },
        },
        type: 528,
      };
      if (this.streamSelect == "QingLiu") {
        streamData.data.messageID = 1017;
        streamData.data.data.status = this.record;
      } else {
        streamData.data.messageID = 1006;
        streamData.data.data.recordControl = this.record;
      }
      this.$emit("fn", streamData);
      if (this.taskId && this.record) {
        let dataRun = {
          taskId: this.taskId,
          typeId: 25,
          deviceId: this.device.id
        }
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
      }
    },
    async photojz () {
      let streamData = {
        data: {
          data: {
            videoID: 1,
          }
        },
        type: 528
      };
      if (this.streamSelect == "QingLiu") {
        streamData.data.messageID = 1016
      } else {
        streamData.data.messageID = 1007
      }
      this.$emit("fn", streamData);
      this.$message.success("拍照成功！");
      if (this.taskId) {
        let dataRun = {
          taskId: this.taskId,
          typeId: 2,
          deviceId: this.device.id
        }
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
      }
    },
    async photo () {
      // if (!this.taskId) {
      //   this.$message.error("暂无任务！");
      //   return;
      // }
      let blob = this.screenShot();
      let fd = new FormData();
      fd.append("uploadFile", blob, `拍照.png`);
      if (this.taskId) {
        fd.append("taskId", this.taskId);
      }
      fd.append("deviceHardId", this.device.deviceHardId);
      let res = await API.FCKERNEL.Upload(fd);
      if (this.taskId) {
        let dataRun = {
          taskId: this.taskId,
          typeId: 1,
          deviceId: this.device.id,
        };
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
      }
      this.$message.success("截图成功！");
      let imgshowurl = process.env.VUE_APP_IMG_URL + "/uploads" + res.fileKey;
      this.$emit("imgUrl", imgshowurl);
    },
    handle (command) {
      this.getcanvas(command);
    },
    // 东台项目
    async handleAI () {
      try {
        if(!this.aiMode) {
          this.dialogFormVisible = true
        } else {
          await API.MAPMANAGE.disableAI({
            bid: this.data.deviceHardId,
          });
          this.aiMode = false
          this.aiEnable = false
        }
      } catch (error) {
        console.log(error);
      }
    },
     // 东台项目
    async sureAIOpen() {
      try {
        this.dialogFormVisible = false
        await API.MAPMANAGE.enableAI({
          ...this.aiForm,
          bid: this.data.deviceHardId,
          uavId: this.data.deviceHardId,
          videoStream: this.vUrl.vUrl,
        });
        this.aiMode = true
        this.aiEnable = true
      } catch (error) {
        console.log(error);
      }
    },
    // 人脸车牌识别
    async getcanvas (command) {
      let blob = this.screenShot();
      let fd = new FormData();
      fd.append("uploadFiles", blob, `下载.jpeg`);
      let res = await API.MAPMANAGE.fileUpload(fd);
      if (command == "a") {
        this.aiuse(res[0].storage);
      } else {
        this.plate(res[0].storage);
      }
    },
    async aiuse (url) {
      let data = {
        imageUrl: url,
        deviceHardId: this.device.deviceHardId,
      };
      this.$el_message("正在识别中...", () => { }, "info", 0);
      let res = await API.AIUSE.aiphoto(data);
      let dataRun = {
        taskId: this.taskId,
        deviceId: this.device.id,
      };
      if (res.code == 200) {
        if (this.taskId) {
          dataRun.typeId = 5;
          let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
        }
        this.$el_message("识别成功！", () => { }, "success");
        this.faceAiShow = true;
      } else if (res.code == 201) {
        this.$el_message(res.message, () => { }, "error");
        if (this.taskId) {
          dataRun.typeId = 7;
          let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
        }
      } else {
        this.$el_message(res.message, () => { }, "error");
        if (this.taskId) {
          dataRun.typeId = 6;
          let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
        }
      }
    },
    async plate (url) {
      let data = {
        imgUrl: url,
        deviceHardId: this.device.deviceHardId,
      };
      this.$el_message("正在识别中...", () => { }, "info", 0);
      let res = await API.AIUSE.palteId(data);
      let dataRun = {
        taskId: this.taskId,
        deviceId: this.device.id,
      };
      if (res.code == 200) {
        if (this.taskId) {
          dataRun.typeId = 8;
          let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
        }
        this.$el_message("识别完成", () => { }, "success");
        this.carList = res.data;
        this.carAiShow = true;
      } else if (res.code == 201) {
        this.$el_message(res.message, () => { }, "error");
        if (this.taskId) {
          dataRun.typeId = 10;
          let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
        }
      } else {
        this.$el_message(res.message, () => { }, "error");
        if (this.taskId) {
          dataRun.typeId = 9;
          let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
        }
      }
    },
    /**
     * 居中
     */
    wrapCenter () {
      this.$nextTick(() => {
        let width = this.$refs.video.clientWidth;
        let height = this.$refs.video.clientHeight;
        let fullWidth = window.innerWidth;
        let fullHeight = window.innerHeight - 100;

        let centerX = fullWidth / 2 - width / 2;
        let centerY = fullHeight / 2 - height / 2;

        // 设置右上角
        // centerX = window.innerWidth - width - 100;
        // centerY = window.innerHeight * 0.13 - 87;


        // this.$refs.wrap.setAttribute("data-x", centerX);
        // this.$refs.wrap.setAttribute("data-y", centerY);
        // this.$refs.wrap.setAttribute(
        //   "style",
        //   `transform: translate(${centerX}px, ${centerY}px)`
        // );
      });
    },
    // 视频窗口位置往下移，在挂载下面
    wrapMoveLow () {
      this.$nextTick(() => {
        let width = this.$refs.video.clientWidth;
        let height = this.$refs.video.clientHeight;
        let fullWidth = window.innerWidth;
        let fullHeight = window.innerHeight - 100;

        // let centerX = fullWidth / 2 - width / 2;
        // let centerY = fullHeight / 2 - height / 2;

        // 设置右上角
        // centerX = window.innerWidth - width - 100;
        // centerY = window.innerHeight * 0.13 - 87 + 332


        // this.$refs.wrap.setAttribute("data-x", centerX);
        // this.$refs.wrap.setAttribute("data-y", centerY);
        // this.$refs.wrap.setAttribute(
        //   "style",
        //   `transform: translate(${centerX}px, ${centerY}px)`
        // );
      });
    },
    /**
     * 视频指点
     */
    async fn (el) {
      let { mountData, device } = this;
      // if mountStatus =1 代表启用 屏幕指点功能
      if (
        mountData.mountStatus == 1 &&
        device.deviceHardId == mountData.deviceHardId &&
        mountData.moveType == "wrj"
      ) {
        let evt = window.event || el;
        var div = null;

        if (this.streamSelect == "QingLiu") {
          // div = document.getElementById("canvas_layer0") ;
          div = document.getElementsByClassName("qingliu")[0];
        } else {
          div = document.getElementsByClassName("vjs-tech")[0];
        }
        let data = {
          x: (evt.offsetX * 1920) / div.offsetWidth - 960,
          y: -((evt.offsetY * 1080) / div.offsetHeight) + 540,
          w: 1920,
          h: 1080,
        };
        let buffer = null;

        if (mountData.name == "MMC_Gimbal_Z30Pro") {
          // z30pro
          buffer = await to_moveMount.move_track_focus_ctrl(data.x, data.y);
        } else if (mountData.name == "MMC_Gimbal_Z40S") {
          //z40s
          buffer = await to_moveMount.move_or_focus_ctrl(data);
        } else {
          buffer = await to_moveMount.gcs_click_coordinates(data);
        }
        let mounteList = { data: buffer, ...mountData };
        if (buffer) {
          this.$emit("directiveFn", mounteList);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  width: 100%;
  height: 100%;
}

.noStyle {
  opacity: 0.3;
}

.cpt_video {
  cursor: default !important;
  // border: 1px solid #fff;
  width: 730px;
  height: 450px;
  background: #333;
  position: fixed;
  width: 587px;
  // height: 347px;
  height: 330px;
  padding-top: 26px;

  .video {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    height: 26px;
    background: #19334d;

    .name {
      position: absolute;
      top: 0;
      left: 0;
      // width: 122px;
      height: 26px;
      // background: #000000;
      border-radius: 1px;
      opacity: 0.6;
      line-height: 26px;
      text-align: center;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
    }

    .type {
      position: absolute;
      top: 0;
      right: 112px;
      width: 56px;
      height: 26px;
      text-align: center;
      line-height: 26px;
      background: #fc8959;
      border-radius: 13px;
      opacity: 0.58;
      backdrop-filter: blur(6.7px);
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
    }

    .types {
      position: absolute;
      top: 0;
      right: 50px;
      width: 56px;
      height: 26px;
      text-align: center;
      line-height: 26px;
      background: #4d9dff;
      border-radius: 13px;
      opacity: 0.58;
      backdrop-filter: blur(6.67px);
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      width: 46px;
      height: 26px;
      text-align: center;
      line-height: 26px;
      // background: #000000;
      border-radius: 1px;
      opacity: 0.6;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
    }
  }

  .info {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    padding: 0 10px;
    padding-top: 30px;
    height: calc(100% - 75px);
    background: rgba($color: #000000, $alpha: 0.6);

    .de {
      margin-top: 8px;
      display: flex;
      color: #fff;
      font-size: 14px;

      .detail {
        margin-left: 8px;
      }
    }
  }

  .play {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 46px;
    background: #000000;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .left {
      color: #fff;

      span {
        font-size: 20px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .search {
        .video_type {
          width: 96px;
          height: 26px;
        }

        .big_type {
          width: 70px;
          height: 26px;
        }

        /deep/.el-input--suffix {
          width: 100%;
          height: 100%;

          .el-input__inner {
            width: 100%;
            height: 100%;
          }
        }

        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.big_01 {
  width: 416px;
  height: 274px;
}

.big_03 {
  width: 1000px;
  height: 600px;
}

.big_04 {
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
}

.ai {
  bottom: 45%;
  z-index: 1;
}

.plate {
  bottom: 75%;
  z-index: 1;
}

.record {
  bottom: 65%;
  z-index: 1;
}

.photojz {
  bottom: 55%;
  z-index: 1;
}

.full-video ::v-deep {
  transform: none !important;

  .interact-wrap {
    transform: translate(0px, -100px) !important;
  }

  .cpt_video {
    position: relative;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vw;
    z-index: 0 !important;

    .ai {
      top: 640px;
      right: 15px;
      height: 36px;
    }

    .faceAndcar {
      top: 503px;
      height: 22px;
    }

    .plate {
      top: 520px;
      right: 15px;
      height: 36px;
    }

    .record {
      top: 560px;
      right: 15px;
      height: 36px;
    }

    .photojz {
      top: 600px;
      right: 15px;
      height: 36px;
    }
  }
}

.modelStyle {
  bottom: 45px;
  right: 12px;
  z-index: 3;
}

// position: relative;
.faceAndcar {
  z-index: 1;
  width: 55px;
  bottom: 46.5%;
  display: flex;
  justify-content: space-between;

  .plan {
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 0 5px;
    background: rgba(9, 32, 87, 0.7);
  }
}

.kedu {
  z-index: 1001;
  top: calc(50% - 25%);
  left: calc(50% - 25%);
  width: 50%;
  height: 50%;
  position: absolute;
  // background: yellow;
  background: url("~@/assets/images/mount/line.png");
  background-size: 100% 100%;
  transition: 0.5s;

  // overflow-y: hidden;
  .num {
    position: absolute;
    left: -30px;
    z-index: 1002;
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .line {
    // transform: translateY(-250px);
    position: absolute;
    bottom: calc(50%);
    width: 100%;
    height: 100%;
  }

  .longLine {
    position: absolute;
    // top: calc(50% - 25px);
    // left: calc(50% - 25px);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .text {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% + 25px);
    width: 75px;
    height: 25px;
    background: url("~@/assets/images/mount/textbg.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 25px;
    color: red;
    font-weight: bold;
  }

  .center {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
  }
}
</style>

<style lang="scss">

.custom-dialog {
  background-color: #0c1926;

  .el-dialog__header {
    height: 40px;
    background: #133e55;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-dialog__title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-dialog__footer {
    text-align: end;

    .el-button {
      width: 114px;
      height: 40px;
      background: #133d54;
      border-radius: 4px;
      border: 0;
      color: #fff;
    }

    .el-button--primary {
      width: 114px;
      height: 40px;
      background: #007aff;
      border-radius: 4px;
    }
  }

  .el-form-item__content {
    .el-input__inner {
      color: #fff;
    }
  }

  .el-form-item__label {
    color: #fff;
  }
}
</style>
